
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import OrderDetailDisplay from "@/shared/components/Orders/OrderDetailDisplay.vue";
import { useLatestOrder } from "@/shared/composables/useLatestOrder";
import { useLatestQuotation } from "@/shared/composables/useLatestQuotation";
import { useOrder } from "@/shared/composables/useOrder";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { OrderStatus } from "@/shared/types/OrderStatus";
import ProfileBackground from "@/web/views/Profile/ProfileBackground.vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();

    const { redirectToOrders } = useRedirectHelper();

    // Fetching of Latest Order data
    const { shownLatestOrder, latestOrderLoading } = useLatestOrder();
    const { order: latestOrder, orderLoading } = useOrder({
      orderId: computed(() => shownLatestOrder.value?.id ?? ""),
    });

    const { latestQuotation, latestQuotationLoading } = useLatestQuotation();

    const shownQuotation = computed(() => {
      if (latestOrder.value) {
        return latestOrder.value.quotation;
      }

      return latestQuotation.value;
    });

    return {
      t,
      OrderStatus,
      latestOrder,
      shownQuotation,
      redirectToOrders,
      loading: computed(
        () =>
          orderLoading.value ||
          latestOrderLoading.value ||
          latestQuotationLoading.value
      ),
    };
  },
  methods: {},
  components: {
    PageLayout,
    OrderDetailDisplay,
    ProfileBackground,
    LeftOutlined,
  },
});
