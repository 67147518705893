import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "profile-page__container pt-8 pb-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ProfileBackground = _resolveComponent("ProfileBackground")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_OrderDetailDisplay = _resolveComponent("OrderDetailDisplay")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "profile-page" }, {
    "title-navigation": _withCtx(() => [
      _createVNode(_component_a_button, {
        size: "small",
        onClick: _ctx.redirectToOrders
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_LeftOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.t("View Past Orders")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    complexBackground: _withCtx(() => [
      _createVNode(_component_ProfileBackground)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, {
              key: 0,
              active: ""
            }))
          : (_openBlock(), _createBlock(_component_OrderDetailDisplay, {
              key: 1,
              order: _ctx.latestOrder,
              quotation: _ctx.shownQuotation
            }, null, 8, ["order", "quotation"]))
      ])
    ]),
    _: 1
  }))
}